import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../App";
import { STATE } from "../../redux/constants";
import { FAILED_FETCHED_CHURCH, FETCH_CHURCH } from "../../redux/features/church/constants";
import { churchState } from "../../redux/features/church/selectors";
import { FAILED_FETCHED_DEANERY, FETCH_DEANERY } from "../../redux/features/deanery/constants";
import { deaneryState } from "../../redux/features/deanery/selectors";
import { FAILED_FETCHED_DIOCESE, FETCH_DIOCESE } from "../../redux/features/diocese/constants";
import { dioceseState } from "../../redux/features/diocese/selectors";
import { FAILED_FETCHED_PARISH, FETCH_PARISH } from "../../redux/features/parish/constants";
import { parishState } from "../../redux/features/parish/selectors";
import { connect } from "react-redux";
import { setStatus as setStatusDiocese, setPayload as setPayloadDiocese } from '../../redux/features/diocese/actions';
import { setStatus as setStatusParish, setPayload as setPayloadParish } from '../../redux/features/parish/actions';
import { setStatus as setStatusDeanery, setPayload as setPayloadDeanery } from '../../redux/features/deanery/actions';
import { setStatus as setStatusChurch, setPayload as setPayloadChurch } from '../../redux/features/church/actions';

function Login(props) {
  useEffect(() => {
    document.title = props.title || "";

    fetch("https://api.kirke-foto.dk/admin/check", {credentials: 'include'})
        .then(res => res.json())
        .then(
            (result) => {
              if (result) {
                auth.signin(result, () => {
                  // Send them back to the page they tried to visit when they were
                  // redirected to the login page. Use { replace: true } so we don't create
                  // another entry in the history stack for the login page.  This means that
                  // when they get to the protected page and click the back button, they
                  // won't end up back on the login page, which is also really nice for the
                  // user experience.
                  loadData();
                  navigate('/', { replace: true });
                });
              } else {
                auth.signout(() => {});
              }
            },
            (error) => {
              auth.signout(() => {});
            }
        );


  }, [props.title]);

  let auth = useAuth();
  let navigate = useNavigate();

  let usernameField = React.createRef();
  let passwordField = React.createRef();

  const onSubmit = event => {
    const username = usernameField.current.value;
    const password = passwordField.current.value;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: username, password: password }),
      credentials: 'include',
    };
    fetch("https://api.kirke-foto.dk/admin/login", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
              auth.signin(username, () => {
                // Send them back to the page they tried to visit when they were
                // redirected to the login page. Use { replace: true } so we don't create
                // another entry in the history stack for the login page.  This means that
                // when they get to the protected page and click the back button, they
                // won't end up back on the login page, which is also really nice for the
                // user experience.
                loadData();
                navigate('/', { replace: true });
              });
            },
            (error) => {
              console.log("ERROR");
            }
        );
    event.preventDefault();
  }

  function loadData() {
    if (props.dioceseState !== STATE.IS_FETCHED) {
      props.setStatusDiocese(FETCH_DIOCESE);
      fetch("https://api.kirke-foto.dk/dioceses/all", {credentials: 'include'})
        .then(res => res.json())
        .then(
            (result) => {
              props.setPayloadDiocese(result);
            },
            (error) => {
              props.setStatus(FAILED_FETCHED_DIOCESE);
            }
        );
    }
    if (props.parishState !== STATE.IS_FETCHED) {
      props.setStatusParish(FETCH_PARISH);
      fetch("https://api.kirke-foto.dk/parishes/all", {credentials: 'include'})
        .then(res => res.json())
        .then(
            (result) => {
              props.setPayloadParish(result);
            },
            (error) => {
              props.setStatus(FAILED_FETCHED_PARISH);
            }
        );
    }
    if (props.deaneryState !== STATE.IS_FETCHED) {
      props.setStatusDeanery(FETCH_DEANERY);
      fetch("https://api.kirke-foto.dk/deaneries/all", {credentials: 'include'})
        .then(res => res.json())
        .then(
            (result) => {
              props.setPayloadDeanery(result);
            },
            (error) => {
              props.setStatus(FAILED_FETCHED_DEANERY);
            }
        );
    }
    if (props.churchState !== STATE.IS_FETCHED) {
      props.setStatusChurch(FETCH_CHURCH);
      fetch("https://api.kirke-foto.dk/churches", {credentials: 'include'})
        .then(res => res.json())
        .then(
            (result) => {
              props.setPayloadChurch(result);
            },
            (error) => {
              props.setStatus(FAILED_FETCHED_CHURCH);
            }
        );
    }
  }

  return (
    <div>
      <>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label>Brugernavn</label>
          <input type="text" className="form-control" name="username" ref={usernameField} />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input type="password" className="form-control" name="password" ref={passwordField} />
        </div>
        <input type="submit" className="btn btn-primary" value="Log ind" />
      </form>
      </>
    </div>
  );
}

const mapStateToProps = state => ({
  dioceseState: dioceseState(state),
  parishState: parishState(state),
  deaneryState: deaneryState(state),
  churchState: churchState(state),
});

export default connect(mapStateToProps, {
  setStatusDiocese,
  setPayloadDiocese,
  setStatusParish,
  setPayloadParish,
  setStatusDeanery,
  setPayloadDeanery,
  setStatusChurch,
  setPayloadChurch,
})(Login);
