import { useEffect } from "react";

function About(props) {
  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);

  return (
    <div>
      <>
      About
      </>
    </div>
  );
}

export default About;
