import React from "react";
import { withRouter } from "../../util";
import view from "./view";

import { connect } from "react-redux";
import { dioceses, dioceseState } from './../../redux/features/diocese/selectors';
import { parishes, parishState } from './../../redux/features/parish/selectors';
import { deaneries, deaneryState } from './../../redux/features/deanery/selectors';
import { churches, churchState } from './../../redux/features/church/selectors';
import { STATE } from '../../redux/constants';

/*function ChurchSingle(props) {
  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);

  let { parishUrl, churchUrl } = useParams();

  return (
    <div>
      <>
      Kirke Single {parishUrl} in {churchUrl}
      </>
    </div>
  );
}*/

class ChurchSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Kirke",
      parishUrl: "",
      churchUrl: "",
      church: null,
      parish: null,
      images: null,
      loading: true,
    }

  }

  componentDidMount() {
    this.setState({
      parishUrl: this.props.params.parishUrl,
      churchUrl: this.props.params.churchUrl,
    }, () => {
      this.getChurch();
    });
    document.title = this.state.title || "";
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params.parishUrl !== this.props.params.parishUrl) {
      this.setState({
        parishUrl: this.props.params.parishUrl,
      }, () => {
        this.getChurch();
      });
    }
    if (prevProps.params.churchUrl !== this.props.params.churchUrl) {
      this.setState({
        churchUrl: this.props.params.churchUrl,
      }, () => {
        this.getChurch();
      });
    }
    if (prevProps.parishState !== this.props.parishState && this.props.parishState === STATE.IS_FETCHED) {
      this.getChurch();
    }

    if (prevProps.churchState !== this.props.churchState && this.props.churchState === STATE.IS_FETCHED) {
      this.getChurch();
    }
    
    document.title = this.state.title || "";
  }

  getChurch() {
    if (this.props.parishState === STATE.IS_FETCHED && this.props.churchState === STATE.IS_FETCHED) {
      const church = this.props.churches.find(church => church.url === this.state.churchUrl);
      fetch("https://api.kirke-foto.dk/churches/images/" + church.id)
        .then(res => res.json())
        .then(
            (result) => {
              this.setState({
                images: result
              });
            },
            (error) => {
              console.log('ERROR');
            }
      );
      this.setState({
        church,
        parish: this.props.parishes.find(parish => parish.url === this.state.parishUrl),
        title: church.name,
        loading: false,
      });
    }
  }

  render = view;
}

const mapStateToProps = state => ({
  dioceses: dioceses(state),
  parishes: parishes(state),
  deaneries: deaneries(state),
  churches: churches(state),
  dioceseState: dioceseState(state),
  parishState: parishState(state),
  deaneryState: deaneryState(state),
  churchState: churchState(state),
});

export default connect(mapStateToProps, {})(withRouter(ChurchSingle));
