import React from 'react';
import { withRouter } from '../../util';
import view from "./view";

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {search: ''};
        this.onFormChange = this.onFormChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
      }

    onFormSubmit = event => {
        console.log(this.state);
        event.preventDefault();
    }

    onFormChange = event => {
        this.setState({search: event.target.value});
    }

    render = view;
}

export default withRouter(Header);
