import { createSelector } from "reselect";
const stateI = state => state.church.state;
const churchesI = state => state.church.churches;

export const churchState = createSelector(
    [stateI],
    state => state
);

export const churches = createSelector(
    [churchesI],
    churches => churches
);
