import React from "react";


export default function render() {
    if (this.state.loading) {
        return (
            <>Loading</>
        )
    }
    return (
        <div>
        <>
            <>{this.state.parish ? this.state.parish.name : ''}<br />{this.state.church ? this.state.church.name : ''}</>
            <br /><br /><br />
            {this.state.images ? this.state.images.map(image => (
                <React.Fragment key={image.id}>Image: {image.id} {image.name}</React.Fragment>
            )) : null}
        </>
        </div>
    );
}
