import "./style.scss";
import { NavLink } from "react-router-dom";


export default function render() {
    let auth = this.props.useAuth;

    return (
        <header className="header">
            <div className="logo">
                <h1>Kirke-Foto Admin</h1>
            </div>
            <nav>
                {auth.user ? (
                    <ul className="menu">
                        <li><NavLink to="/" className={({isActive}) => (isActive ? "active" : '')}>Kirker</NavLink></li>
                        <li><NavLink to="/om-os" className={({isActive}) => (isActive ? "active" : '')}>Sogne</NavLink></li>
                    </ul>
                ) : null}
            </nav>
        </header>
    );
}