import { useParams } from "react-router-dom";
import { useAuth } from "./App";

export const withRouter = WrappedComponent => props => {
    const params = useParams();
    let auth = useAuth();
    // etc... other react-router-dom v6 hooks

    return (
      <WrappedComponent
        {...props}
        params={params}
        useAuth={auth}
        // etc...
      />
    );
};