import './App.css';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from "react-router-dom";
import Homepage from './pages/homepage/component';
import About from './pages/about/component';
import Header from "./components/header/component";
import ChurchSingle from './pages/church-single/component';
import Content from './components/content/component';
import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { dioceseState } from './redux/features/diocese/selectors';
import { setStatus as setStatusDiocese, setPayload as setPayloadDiocese } from './redux/features/diocese/actions';
import { setStatus as setStatusParish, setPayload as setPayloadParish } from './redux/features/parish/actions';
import { setStatus as setStatusDeanery, setPayload as setPayloadDeanery } from './redux/features/deanery/actions';
import { setStatus as setStatusChurch, setPayload as setPayloadChurch } from './redux/features/church/actions';
import { FAILED_FETCHED_DIOCESE, FETCH_DIOCESE } from './redux/features/diocese/constants';
import { FAILED_FETCHED_PARISH, FETCH_PARISH } from './redux/features/parish/constants';
import { FAILED_FETCHED_DEANERY, FETCH_DEANERY } from './redux/features/deanery/constants';
import { FAILED_FETCHED_CHURCH, FETCH_CHURCH } from './redux/features/church/constants';
import { parishState } from './redux/features/parish/selectors';
import { deaneryState } from './redux/features/deanery/selectors';
import { churchState } from './redux/features/church/selectors';
import { STATE } from './redux/constants';
import NotFound from './pages/not-found/component';
import Login from './pages/login/component';



const fakeAuthProvider = {
  isAuthenticated: false,
  signin(callback) {
    fakeAuthProvider.isAuthenticated = true;
    setTimeout(callback, 100); // fake async
  },
  signout(callback) {
    fakeAuthProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};
let AuthContext = React.createContext(null);
function AuthProvider({ children }) {
  let [user, setUser] = React.useState(null);

  let signin = (newUser, callback) => {
    return fakeAuthProvider.signin(() => {
      setUser(newUser);
      callback();
    });
  };

  let signout = (callback) => {
    return fakeAuthProvider.signout(() => {
      setUser(null);
      callback();
    });
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
function App(props) {

  return (
    <AuthProvider>
      <Router>
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Header />
          <Content>
            <Routes>
              <Route path="/" exact element={<RequireAuth><Homepage title="Forside" /></RequireAuth>} />
              <Route path="/om-os" exact element={<RequireAuth><Header /><About title="Om os" /></RequireAuth>} />
              <Route path="/login" exact element={<Login title="Login" />} />
              <Route path="/kirke/:parishUrl/:churchUrl" exact element={<ChurchSingle />} />
              <Route path="*" element={<NotFound title="Siden er ikke fundet" />} />
            </Routes>
          </Content>
      </Router>
    </AuthProvider>
  );
}
/*class App extends React.Component {

  componentDidMount() {
    console.log(this.props);
    let auth = this.props.auth();

    fetch("https://api.kirke-foto.dk/admin/check", {credentials: 'include'})
        .then(res => res.json())
        .then(
            (result) => {
              console.log(result);
              if (!result) {
                  auth.signin('test', () => {
                    // Send them back to the page they tried to visit when they were
                    // redirected to the login page. Use { replace: true } so we don't create
                    // another entry in the history stack for the login page.  This means that
                    // when they get to the protected page and click the back button, they
                    // won't end up back on the login page, which is also really nice for the
                    // user experience.
                  });
              }
            },
            (error) => {
              console.log("ERROR");
            }
        );
    if (this.props.dioceseState !== STATE.IS_FETCHED) {
      this.props.setStatusDiocese(FETCH_DIOCESE);
      fetch("https://api.kirke-foto.dk/dioceses")
        .then(res => res.json())
        .then(
            (result) => {
              this.props.setPayloadDiocese(result);
            },
            (error) => {
              this.props.setStatus(FAILED_FETCHED_DIOCESE);
            }
        );
    }
    if (this.props.parishState !== STATE.IS_FETCHED) {
      this.props.setStatusParish(FETCH_PARISH);
      fetch("https://api.kirke-foto.dk/parishes")
        .then(res => res.json())
        .then(
            (result) => {
              this.props.setPayloadParish(result);
            },
            (error) => {
              this.props.setStatus(FAILED_FETCHED_PARISH);
            }
        );
    }
    if (this.props.deaneryState !== STATE.IS_FETCHED) {
      this.props.setStatusDeanery(FETCH_DEANERY);
      fetch("https://api.kirke-foto.dk/deaneries")
        .then(res => res.json())
        .then(
            (result) => {
              this.props.setPayloadDeanery(result);
            },
            (error) => {
              this.props.setStatus(FAILED_FETCHED_DEANERY);
            }
        );
    }
    if (this.props.churchState !== STATE.IS_FETCHED) {
      this.props.setStatusChurch(FETCH_CHURCH);
      fetch("https://api.kirke-foto.dk/churches")
        .then(res => res.json())
        .then(
            (result) => {
              this.props.setPayloadChurch(result);
            },
            (error) => {
              this.props.setStatus(FAILED_FETCHED_CHURCH);
            }
        );
    }
  }


  render() {
    return (
      <AuthProvider>
        <Router>
            <Content>
              <Routes>
                <Route path="/" exact element={<RequireAuth><Header /><Homepage title="Forside" /></RequireAuth>} />
                <Route path="/om-os" exact element={<RequireAuth><Header /><About title="Om os" /></RequireAuth>} />
                <Route path="/login" exact element={<Login title="Login" />} />
                <Route path="/kirke/:parishUrl/:churchUrl" exact element={<ChurchSingle />} />
                <Route path="*" element={<NotFound title="Siden er ikke fundet" />} />
              </Routes>
            </Content>
        </Router>
      </AuthProvider>
    );
  }
}*/
const mapStateToProps = state => ({
  dioceseState: dioceseState(state),
  parishState: parishState(state),
  deaneryState: deaneryState(state),
  churchState: churchState(state),
});

export default connect(mapStateToProps, {
  setStatusDiocese,
  setPayloadDiocese,
  setStatusParish,
  setPayloadParish,
  setStatusDeanery,
  setPayloadDeanery,
  setStatusChurch,
  setPayloadChurch,
})(App);
