import React, { useEffect, useState } from "react";
import Search from "../../components/search/component";
import { Column, Table, SortDirection, AutoSizer } from "react-virtualized";
import { STATE } from "../../redux/constants";
import { FAILED_FETCHED_CHURCH, FETCH_CHURCH } from "../../redux/features/church/constants";
import { churches, churchState } from "../../redux/features/church/selectors";
import { FAILED_FETCHED_DEANERY, FETCH_DEANERY } from "../../redux/features/deanery/constants";
import { deaneries, deaneryState } from "../../redux/features/deanery/selectors";
import { FAILED_FETCHED_DIOCESE, FETCH_DIOCESE } from "../../redux/features/diocese/constants";
import { dioceses, dioceseState } from "../../redux/features/diocese/selectors";
import { FAILED_FETCHED_PARISH, FETCH_PARISH } from "../../redux/features/parish/constants";
import { parishes, parishState } from "../../redux/features/parish/selectors";
import { connect } from "react-redux";
import { setStatus as setStatusDiocese, setPayload as setPayloadDiocese } from '../../redux/features/diocese/actions';
import { setStatus as setStatusParish, setPayload as setPayloadParish } from '../../redux/features/parish/actions';
import { setStatus as setStatusDeanery, setPayload as setPayloadDeanery } from '../../redux/features/deanery/actions';
import { setStatus as setStatusChurch, setPayload as setPayloadChurch } from '../../redux/features/church/actions';
import style from "./style.scss";
import { Droppable, Draggable, DragDropContext } from "react-virtualized-dnd";

/**
 * Extracts the text content of a React/JSX element
 * @param {PropTypes.node} elm The element to get the text content of
 */
 export function elementToText(elm) {
  if (!elm) { return ""; }
  if (typeof elm === "string") { return elm; }
  if (typeof elm.props.children === "string") {
      return elm.props.children;
  }
  return React.Children.toArray(elm.props.children)
      .map(elementToText)
      .join("\n");
}

function Homepage(props) {
  const [churches, setChurches] = useState([]);
  const [editImages, setEditImages] = useState(0);
  const [editChurch, setEditChurch] = useState(0);
  const [editCommunication, setEditCommunication] = useState(0);
  const [church, setChurch] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [dateField, setDateField] = useState(new Date().toISOString().split('T')[0]);
  const [descriptionField, setDescriptionField] = useState('');
  const [churchDroneField, setChurchDroneField] = useState(false);
  const [churchOpenAreaField, setChurchOpenAreaField] = useState(false);
  const [churchDescriptionField, setChurchDescriptionField] = useState('');
  const [churchSeoTagsField, setChurchSeoTagsField] = useState('');
  const [churchSeoDescriptionField, setChurchSeoDescriptionField] = useState('');
  useEffect(() => {
    document.title = props.title || "";
    setList(props.churches);
    if (church && editImages === 1) {
      images(church.id);
    }
  }, [props.title, props.churches]);

  function loadData() {
    fetch("https://api.kirke-foto.dk/churches", {credentials: 'include'})
      .then(res => res.json())
      .then(
          (result) => {
            props.setPayloadChurch(result);
          },
          (error) => {
            props.setStatus(FAILED_FETCHED_CHURCH);
          }
      );
  }

  const onFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

  const onDateChange = (event) => {
		setDateField(event.target.value);
	};

  const onDescriptionChange = (event) => {
		setDescriptionField(event.target.value);
	};

  const onChurchDroneChange = (event) => {
		setChurchDroneField(event.target.checked);
	};
  
  const onChurchOpenAreaChange = (event) => {
		setChurchOpenAreaField(event.target.checked);
	};

  const onChurchDescriptionChange = (event) => {
		setChurchDescriptionField(event.target.value);
	};

  const onChurchSeoTagsChange = (event) => {
		setChurchSeoTagsField(event.target.value);
	};

  const onChurchSeoDescriptionChange = (event) => {
		setChurchSeoDescriptionField(event.target.value);
	};
  
  const onSave = () => {
    if (!selectedFile) { return }
    const formData = new FormData();
    formData.append('date_taken', dateField ? dateField : new Date().toISOString().split('T')[0]);
    formData.append('description', descriptionField);
		formData.append('image', selectedFile);

		fetch('https://api.kirke-foto.dk/churches/images/'+church.id, { method: 'POST', body: formData, credentials: 'include', })
			.then((response) => response.json())
			.then((result) => {
				console.log('Success:', result);
        setEditImages(1);
        loadData();
			})
			.catch((error) => {
				console.error('Error:', error);
			});
  }


  /**
   * Translates options into their text content
   * Textified is in format { text: "...", children: [...] }
   * If mapping array is provided, populates with {value:any, text:string}'s
   */
  const textifyOptions = (options, mapping) =>{
    return options.map(option => {
        const text = elementToText(option.name);
        if (mapping) {
            mapping.push({ value: option.id, text, });
        }
        const outp = { text, };
        if (option.children) {
            outp.children = textifyOptions(option.children, mapping);
        }
        return outp;
    });
  }

  const getAdditionalOptions = options => {
    const newOptions = [...options];

    return newOptions;
}

  const onSearch = event => {
    let valueToText = [];
    const value = event.target.value.toLowerCase();
    let optionsAsText = textifyOptions(
      getAdditionalOptions(props.churches), valueToText
    );
    let filteredOptions = props.churches;
    if (value.length >= 3){
      // converts to entries of format { matches: true, children: [...] }
      const toMatchesArray = options => options.map(entry => {
        const outp = {};
        // do stuff
        if (entry.children) {
            outp.children = toMatchesArray(entry.children);
        }
        // check if entry itself matched
        outp.matches = entry.text.toLowerCase().indexOf(value) !== -1;
        // if entry itself matched, get all children in
        if (outp.matches && outp.children) {
            for (let i = 0; i < outp.children.length; ++i) {
                outp.children[i].matches = true;
            }
        }
        // check if any children matched
        if (!outp.matches && outp.children) {
            for (let i = 0; i < outp.children.length; ++i) {
                if (outp.children[i].matches) {
                    outp.matches = true;
                    break;
                }
            }
        }
        return outp;
      });
      // applies a matchesArray (from above) to a tree
      const applyMatchesArray = (options, matches) => options.map((entry, i) => {
          const isMatch = matches[i].matches;
          if (!isMatch) { return null; }
          const outp = { ...entry };
          if (outp.children) {
              outp.children = applyMatchesArray(outp.children, matches[i].children);
          }
          return outp;
      }).filter(Boolean);
  
      const matchesArray = toMatchesArray(optionsAsText);
      filteredOptions = applyMatchesArray(filteredOptions, matchesArray);

      setList(filteredOptions);
    } else {
      setList(props.churches);
    }
  }

  function setList(data) {
    if (data) {
      let list = [];
      data.forEach(church => {
        list.push({
          id: church.id,
          name: church.name,
          images: church.images ? church.images.length : 0,
          communications: church.communications ? church.communications.length : 0,
          drone_approval: church.drone_approval,
          seo_tags: church.seo_tags,
          seo_description: church.seo_description
        });
      })
      setChurches(list);
    }
  }

  function edit(churchId) {
    const church = props.churches.find(church => church.id === churchId);
    console.log(churchId, church);
    setEditChurch(churchId);
    setChurchDroneField(church.drone_approval);
    setChurchOpenAreaField(church.open_area);
    setChurchDescriptionField(church.description);
    setChurchSeoTagsField(church.seo_tags);
    setChurchSeoDescriptionField(church.seo_description);
  }

  function saveChurch() {
    const headers = {
      "Content-Type": "application/json",
    };
		fetch('https://api.kirke-foto.dk/churches/'+editChurch, {
      method: 'PUT',
      body: JSON.stringify({
        seo_tags: churchSeoTagsField,
        seo_description: churchSeoDescriptionField,
        drone_approval: churchDroneField ? 1 : 0,
        open_area: churchOpenAreaField ? 1 : 0,
        description: churchDescriptionField
      }),
      credentials: 'include',
      headers 
    })
			.then((response) => response.json())
			.then((result) => {
				console.log('Success:', result);
        setEditChurch(0);
        loadData();
			})
			.catch((error) => {
				console.error('Error:', error);
			});
  }

  function deleteImage(imageId) {
    if(window.confirm('Are you sure?')) {
      console.log(imageId);
      fetch('https://api.kirke-foto.dk/churches/images/'+imageId, { method: 'DELETE', credentials: 'include', })
			.then((response) => response.json())
			.then((result) => {
				console.log('Success:', result);
        setEditImages(1);
        loadData();
			})
			.catch((error) => {
				console.error('Error:', error);
			});
    }
  }

  function images(churchId) {
    const church = props.churches.find(church => church.id === churchId);
    setChurch(church);
    setEditImages(1);
  }

  function communication(churchId) {
    const church = props.churches.find(church => church.id === churchId);
    setChurch(church);
    setEditCommunication(1);
  }

  const saveCommunication = () => {
    const formData = new FormData();
    formData.append('date', dateField ? dateField : new Date().toISOString().split('T')[0]);
    formData.append('type', descriptionField);

		fetch('https://api.kirke-foto.dk/churches/communications/'+church.id, { method: 'POST', body: formData, credentials: 'include', })
			.then((response) => response.json())
			.then((result) => {
				console.log('Success:', result);
        setEditCommunication(1);
        loadData();
			})
			.catch((error) => {
				console.error('Error:', error);
			});
  }

  function deleteCommunication(communicationId) {
    if(window.confirm('Are you sure?')) {
      console.log(communicationId);
      fetch('https://api.kirke-foto.dk/churches/communications/'+communicationId, { method: 'DELETE', credentials: 'include', })
			.then((response) => response.json())
			.then((result) => {
				console.log('Success:', result);
        setEditCommunication(1);
        loadData();
			})
			.catch((error) => {
				console.error('Error:', error);
			});
    }
  }

  
  //const listDa = [];
  const numItems = 10;
  const numColumns = 6;
  const dragAndDropGroupName = "exampleboard";
  const onDragEnd = (source, destinationId, placeholderId) => {
    const tmp = [...church.images];
    const listToRemoveFrom = tmp;
    const listToAddTo = tmp;
    const elemToAdd = listToRemoveFrom.find(
      (entry) => entry.id === parseInt(source.draggableId)
    );
    let indexToRemove = listToRemoveFrom.findIndex(
      (item) => item.id === parseInt(source.draggableId)
    );
    let indexToInsert =
      placeholderId === "END_OF_LIST"
        ? listToAddTo.length
        : placeholderId.includes("header")
        ? 0
        : listToAddTo.findIndex((item) => item.id === parseInt(placeholderId));
    // Re-arrange within the same list
    if (listToRemoveFrom.name === listToAddTo.name) {
      if (indexToRemove === indexToInsert) {
        return;
      }
      // If we're moving an element below the insertion point, indexes will change.
      const direction = indexToRemove < indexToInsert ? 1 : 0;
      listToRemoveFrom.splice(indexToRemove, 1);
      listToAddTo.splice(indexToInsert - direction, 0, elemToAdd);
    } else {
      listToRemoveFrom.splice(indexToRemove, 1);
      listToAddTo.splice(indexToInsert, 0, elemToAdd);
    }

    const newData = tmp;
    if (listToAddTo.index) {
      newData[listToAddTo.index] = listToAddTo;
    }
    const newChurch = structuredClone(church);
    newChurch.images = newData;
    setChurch(newChurch);

    const formData = new FormData();
    formData.append('order', JSON.stringify(newChurch.images));
    fetch('https://api.kirke-foto.dk/churches/images/order/'+church.id, { method: 'PUT', body: JSON.stringify(newChurch.images), credentials: 'include', })
			.then((response) => response.json())
			.then((result) => {
				console.log('Success:', result);
        setEditImages(1);
        loadData();
			})
			.catch((error) => {
				console.error('Error:', error);
			});
  };
  
  if (!props.churches || !props.parishes || !props.deaneries || !props.dioceses) {
    return (<>Loading</>);
  }
  if (props.churches.length === 0 || props.parishes.length === 0 || props.deaneries.length === 0 || props.dioceses.length === 0) {
    return (<>Loading</>);
  }

  function sortDrone()
  {
    setList(props.churches.filter(church => {
      if (church.images) {
        var images = church.images.filter(image => image.drone);
        if (images.length === 0 && church.drone_approval) {
          return true;
        }
      }
      return false;
    }));
  }

  function sortDone()
  {
    setList(props.churches.filter(church => {
      if (church.images) {
        var images = church.images.filter(image => image.drone);
        if (images.length > 0) {
          return true;
        }
      }
      return false;
    }));
  }

  function sortDroneCommunication()
  {
    setList(props.churches.filter(church => {
      if (church.images) {
        var images = church.images.filter(image => image.drone);
        if (images.length === 0 && church.drone_approval === 0 && church.communications.length > 0) {
          return true;
        }
      }
      return false;
    }));
  }

  function sortCommunication()
  {
    setList(props.churches.filter(church => {
      if (church.images) {
        if (church.communications.length > 0) {
          return true;
        }
      }
      return false;
    }));
  }

  function sortAll()
  {
    setList(props.churches);
  }
  return (
    <div style={{ width: "100%" }}>
      <h1>Kirker</h1>
      <div className="form-group">
        <button className="btn btn-primary" onClick={sortAll}>Se alle kirker</button>
        <button className="btn btn-primary" onClick={sortDrone}>Se kirker /u drone billeder</button>
        <button className="btn btn-primary" onClick={sortDroneCommunication}>Se kirker /u drone billeder og /m kommunication</button>
        <button className="btn btn-primary" onClick={sortCommunication}>Se kirker /m kommunication</button>
        <button className="btn btn-primary" onClick={sortDone}>Se færdige kirker</button>
      </div>
      <div className="form-group">
        <label>Søg</label>
        <input className="form-control" onChange={onSearch} />
      </div>
      <div style={{ height: 400 }}>
        <AutoSizer>
          {({ height, width }) => (
            <Table
              width={width}
              height={height}
              headerHeight={20}
              rowHeight={30}
              rowCount={churches.length}
              rowGetter={({ index }) => churches[index]}
            >
              <Column label="Name" dataKey="name" width={200} />
              <Column width={300} label="Sogn" dataKey cellRenderer={(cellData) => {
                const church = props.churches.find(church => church.id === cellData.rowData.id);
                const parish = props.parishes.find(parish => parish.id === church.parish_id);
                return (
                <div>
                  {parish.name}
                </div>
              )}} />
              <Column width={300} label="Provsti" dataKey cellRenderer={(cellData) => {
                const church = props.churches.find(church => church.id === cellData.rowData.id);
                const parish = props.parishes.find(parish => parish.id === church.parish_id);
                const deanery = props.deaneries.find(deanery => deanery.id === parish.deanery_id);
                return (
                <div>
                  {deanery.name}
                </div>
              )}} />
              <Column width={300} label="Stift" dataKey cellRenderer={(cellData) => {
                const church = props.churches.find(church => church.id === cellData.rowData.id);
                const parish = props.parishes.find(parish => parish.id === church.parish_id);
                const deanery = props.deaneries.find(deanery => deanery.id === parish.deanery_id);
                const diocese = props.dioceses.find(diocese => diocese.id === deanery.diocese_id);
                return (
                <div>
                  {diocese.name}
                </div>
              )}} />
              <Column width={100} label="Images" dataKey="images" />
              <Column width={100} label="Drone" dataKey="drone_approval" />
              <Column width={300} label="Actions" dataKey cellRenderer={(cellData) => (
                <div>
                  <button className="btn btn-default" onClick={() => edit(cellData.rowData.id)}><i className="fa fa-edit"></i></button>
                  <button className="btn btn-default" onClick={() => communication(cellData.rowData.id)}>
                    {cellData.rowData.communications ? 
                      (<span className="fa-stack has-badge" data-count={cellData.rowData.communications}><i className="fa fa-stack-1x fa-inbox"></i></span>)
                      : (<span className="fa-stack has-badge"><i className="fa fa-stack-1x fa-inbox"></i></span>)
                    }
                  </button>
                  <button className="btn btn-default" onClick={() => images(cellData.rowData.id)}><i className="fa fa-camera"></i></button>
                </div>
              )} />
            </Table>
          )}
        </AutoSizer>
      </div>
      {editImages > 0 ? (
        <div id="modal" className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setEditImages(0)}>&times;</span>
            {editImages === 2 ? (
              <>
                <button onClick={() => setEditImages(1)} className="btn btn-primary">Annuller</button>&nbsp;
                <button onClick={onSave} className="btn btn-primary">Upload</button>
                <div className="form-group">
                  <label>Date Taken</label>
                  <input type="date" className="form-control" onChange={onDateChange} />
                </div>
                <div className="form-group">
                  <label>Image</label>
                  <input type="file" onChange={onFileChange} className="form-control" />
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <textarea className="form-control" onChange={onDescriptionChange} />
                </div>
              </>
            ):(
              <>
                <button onClick={() => setEditImages(2)} className="btn btn-primary">Opret</button>
                <DragDropContext
                  dragAndDropGroup={dragAndDropGroupName}
                  onDragEnd={onDragEnd}
                  outerScrollBar={true}
                  scrollContainerHeight={500}
                >
                  <Droppable
                    dragAndDropGroup={dragAndDropGroupName}
                    droppableId={"1"}
                    key={1}
                    containerHeight={500}
                    style={{
                      display: "flex",
                    }}
                  >
                    {church.images.map((item) => (
                      <Draggable
                        dragAndDropGroup={dragAndDropGroupName}
                        draggableId={String(item.id)}
                        dragDisabled={false}
                        key={item.id}
                      >
                        <div
                          className={"draggable-test"}
                          style={{
                            border: "solid 1px black",
                            height: "82px",
                            backgroundColor: "white",
                            flexGrow: 1,
                            display: "flex",
                          }}
                        >
                          <div className="image">
                            <img alt="" width={"100%"} src={"https://api.kirke-foto.dk/storage/images/thumb_"+item.path} />
                          </div>
                          <div className="actions">
                            <button className="btn btn-default" onClick={() => edit(item.id)}><i className="fa fa-edit"></i></button>
                            <button className="btn btn-default" onClick={() => deleteImage(item.id)}><i className="fa fa-trash"></i></button>
                          </div>
                          <p style={{ marginLeft: "5px" }} className={"item-name"}>
                            {item.id} {item.name}
                          </p>
                        </div>
                      </Draggable>
                    ))}
                  </Droppable>
                </DragDropContext>
                {/*<div style={{ height: 400 }}>
                  <AutoSizer>
                    {({ height, width }) => (
                      <Table
                        width={width}
                        height={height}
                        headerHeight={20}
                        rowHeight={100}
                        rowCount={church.images.length}
                        rowGetter={({ index }) => church.images[index]}
                      >
                        <Column width={100} label="Actions" dataKey cellRenderer={(cellData) => (
                          <img alt="" width={100} src={"https://api.kirke-foto.dk/storage/images/thumb_"+cellData.rowData.path} />
                        )} />
                        <Column label="Name" dataKey="name" width={200} />
                        <Column width={300} label="Actions" dataKey cellRenderer={(cellData) => (
                          <div>
                            <button className="btn btn-default" onClick={() => edit(cellData.rowData.id)}><i className="fa fa-edit"></i></button>
                            <button className="btn btn-default" onClick={() => deleteImage(cellData.rowData.id)}><i className="fa fa-trash"></i></button>
                          </div>
                        )} />
                      </Table>
                    )}
                  </AutoSizer>
                </div>*/}
              </>
            )}
          </div>
        </div>
      ):null}
      {editCommunication > 0 ? (
        <div id="modal" className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setEditCommunication(0)}>&times;</span>
            {editCommunication === 2 ? (
              <>
                <button onClick={() => setEditCommunication(1)} className="btn btn-primary">Annuller</button>&nbsp;
                <button onClick={saveCommunication} className="btn btn-primary">Gem</button>
                <div className="form-group">
                  <label>Date</label>
                  <input type="date" className="form-control" onChange={onDateChange} />
                </div>
                <div className="form-group">
                  <label>Type</label>
                  <input className="form-control" onChange={onDescriptionChange} />
                </div>
              </>
            ):(
              <>
                <button onClick={() => setEditCommunication(2)} className="btn btn-primary">Opret</button>
                  <div style={{ height: 400 }}>
                    <AutoSizer>
                      {({ height, width }) => (
                        <Table
                          width={width}
                          height={height}
                          headerHeight={20}
                          rowHeight={30}
                          rowCount={church.communications.length}
                          rowGetter={({ index }) => church.communications[index]}
                        >
                          <Column label="Date" dataKey="date" width={100} />
                          <Column label="Name" dataKey="type" width={800} />
                          <Column width={300} label="Actions" dataKey cellRenderer={(cellData) => (
                            <div>
                              <button className="btn btn-default" onClick={() => deleteCommunication(cellData.rowData.id)}><i className="fa fa-trash"></i></button>
                            </div>
                          )} />
                        </Table>
                      )}
                    </AutoSizer>
                  </div>
              </>
            )}
          </div>
        </div>
      ):null}
      {editChurch > 0 ? (
        <div id="modal" className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setEditChurch(0)}>&times;</span>
            <>
              <button onClick={() => saveChurch()} className="btn btn-primary">Rediger</button>
              <div className="form-group">
                <label>Drone</label>
                <input type="checkbox" checked={churchDroneField ? "checked" : ""} onChange={onChurchDroneChange} />
              </div>

              <div className="form-group">
                <label>Open Area</label>
                <input type="checkbox" checked={churchOpenAreaField ? "checked" : ""} onChange={onChurchOpenAreaChange} />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea className="form-control" onChange={onChurchDescriptionChange}>{churchDescriptionField}</textarea>
              </div>
              <div className="form-group">
                <label>SEO Description</label>
                <textarea className="form-control" onChange={onChurchSeoDescriptionChange}>{churchSeoDescriptionField}</textarea>
              </div>
              <div className="form-group">
                <label>SEO Tags</label>
                <textarea className="form-control" onChange={onChurchSeoTagsChange}>{churchSeoTagsField}</textarea>
              </div>
            </>
          </div>
        </div>
      ):null}
    </div>
  );
}

const mapStateToProps = state => ({
  dioceseState: dioceseState(state),
  parishState: parishState(state),
  deaneryState: deaneryState(state),
  churchState: churchState(state),
  churches: churches(state),
  parishes: parishes(state),
  deaneries: deaneries(state),
  dioceses: dioceses(state),
});

export default connect(mapStateToProps, {
  setStatusDiocese,
  setPayloadDiocese,
  setStatusParish,
  setPayloadParish,
  setStatusDeanery,
  setPayloadDeanery,
  setStatusChurch,
  setPayloadChurch,
})(Homepage);
