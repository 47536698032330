import { DONE_FETCHED_CHURCH } from "./constants";

export function setStatus(STATUS) {
    const action = {
        type: STATUS,
    };
    return action;
}

export function setPayload(payload) {
    const action = {
        type: DONE_FETCHED_CHURCH,
        payload
    };
    return action;
}
